import { Box, FormControl } from "@mui/joy";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";

const DrinkOfChoiceStepRadioButtons = ({
  name,
  options,
  onClickButton,
}: any) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ position: "relative" }}>
      <Controller
        name={name}
        defaultValue={""}
        control={control}

        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <RadioGroup
              {...field}
              defaultValue="person1"
              sx={{
                gap: { xs: 1, lg: 1 },
                mt: 1,
                display: "grid",

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  lg: "repeat(1, 1fr)",
                },
              }}
            >
              {options.map((elem: any, index: number) => (
                <Box                     key={index}

                  sx={{
                    display: "flex",
                    background: "#FFF",
                    borderRadius: "9px",
                    width: { xs: "280px", lg: "349px" },
                  }}
                >
                  <FormControlLabel
                    sx={{ position: "relative", width: "100%", margin: 0 }}
                    value={elem.value}
                    control={
                      <Radio
                        onChange={onClickButton}
                        sx={{
                          fontFamily: "Manrope",
                          color: "#FF3A18",
                          "&.Mui-checked": {
                            color: "#FF3A18",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontFamily: "Manrope", fontWeight: "600" }}
                      >
                        {elem.label}
                      </Typography>
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default DrinkOfChoiceStepRadioButtons;
